const button = document.getElementById('toggle-search');
const nav = document.getElementById('main-navigation');
const input = document.querySelector('#search-input');
const toggle = () => nav.classList.toggle('show-search');

// toggle class on click on button
button.addEventListener('click', toggle);

// toggle class on outside of input or button if class is toggled
document.body.addEventListener('click', (e) => {
    if (!nav.classList.contains('show-search')) return;
    // if(![button, input].includes(e.target)) toggle();
})
