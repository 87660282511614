import * as bootstrap from "bootstrap";

const bannerCarousel = document.querySelector('#bannerCarousel');
if (bannerCarousel) {
    const carousel = new bootstrap.Carousel(bannerCarousel, {
        interval: 3000,
        // ride: true,
        touch: true
    });
}
