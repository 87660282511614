export default class Pagination {
    private readonly formId = 'tx_indexedsearch';
    private readonly pointerId = 'tx_indexedsearch_pointer';
    private readonly freeIndexUidId = 'tx_indexedsearch_freeIndexUid';
    private readonly waiting = 'waiting';

    private readonly form: HTMLFormElement;
    private readonly pointer: string;
    private readonly freeIndexUid: string;

    private wrapper: HTMLElement;
    private query: string;

    constructor() {
        this.form = document.getElementById(this.formId) as HTMLFormElement;
        this.pointer = (document.getElementById(this.pointerId) as HTMLInputElement).name;
        this.freeIndexUid = (document.getElementById(this.freeIndexUidId) as HTMLInputElement).name;
    }

    private renderResponse(response: string): void {
        const doc = new DOMParser().parseFromString(response, 'text/html');
        const responseWrapper = doc.querySelector(this.query);
        if (responseWrapper) {
            this.wrapper.innerHTML = responseWrapper.innerHTML;
            window.scrollTo({top: 0, behavior: 'smooth'});
        }
    }

    private navigate(pointer: number, freeIndexUid: number | string): void {
        const body = new FormData(this.form);
        body.append(this.pointer, `${pointer}`);
        body.append(this.freeIndexUid, `${freeIndexUid}`);

        const addWaitingClass = setTimeout(() => {
            this.wrapper.classList.add(this.waiting);
        }, 500);

        fetch(this.form.action, {method: this.form.method, body})
            .then(response => response.text())
            .then(response => {
                clearTimeout(addWaitingClass);
                this.wrapper.classList.remove(this.waiting);
                this.renderResponse(response);
            });
    }

    private get navigators(): HTMLElement[] {
        return Array.from(this.wrapper.querySelectorAll('[data-page][data-free-index-uid]'));
    }

    private registerPageHandler(): void {
        this.wrapper.addEventListener('click', e => {
            if (e.target instanceof HTMLElement && this.navigators.includes(e.target)) {
                e.preventDefault();
                const {page, freeIndexUid} = e.target.dataset;
                this.navigate(parseInt(page), freeIndexUid);
            }
        });
    }

    public registerPagination(wrapperQuery: string): void {
        this.query = wrapperQuery;
        this.wrapper = document.querySelector(wrapperQuery);
        this.registerPageHandler();
    }
}
